import { SEARCH_BOX_ROW_LAYOUT, SEARCH_FORM_FIELDS_LAYOUT } from '@config/consts/searchBox/layout/home/imovirtualpt2';
import type { HomeConfig } from '@config/home/type';
import { SITE_CONFIG } from '@config/siteConfig';

export const HOME_CONFIG: HomeConfig = {
    canonicalURL: 'https://www.imovirtual.com',
    searchBoxRows: SEARCH_BOX_ROW_LAYOUT,
    searchBoxFieldsLayout: SEARCH_FORM_FIELDS_LAYOUT,
    isNexusHomePageAvailable: true,
    searchSection: {
        isGradientBackground: false,
    },
    propertiesForSellSection: [
        {
            id: 'sell-Lisboa',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsLisboa.webp',
            header: 'Lisboa',
            location: 'lisboa/lisboa',
            translatedLocation: {
                default: 'Lisboa',
            },
        },
        {
            id: 'sell-Vila-Nova-de-Gaia',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsVilaNovaDeGaia.webp',
            header: 'Vila Nova de Gaia',
            location: 'porto/vila-nova-de-gaia',
            translatedLocation: {
                default: 'Vila Nova de Gaia',
            },
            translationKey: 'frontend.homepage.popular-locations-section.link-label-secondary',
        },
        {
            id: 'sell-Sintra',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsCintra.webp',
            header: 'Sintra',
            location: 'lisboa/sintra',
            translatedLocation: {
                default: 'Sintra',
            },
        },
        {
            id: 'sell-Braga',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsBraga.webp',
            header: 'Braga',
            location: 'braga/braga',
            translatedLocation: {
                default: 'Braga',
            },
        },
        {
            id: 'sell-Porto',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsPorto.webp',
            header: 'Porto',
            location: 'porto/porto',
            translatedLocation: {
                default: 'Porto',
            },
        },
        {
            id: 'sell-Seixal',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsSeixal.webp',
            header: 'Seixal',
            location: 'setubal/seixal',
            translatedLocation: {
                default: 'Seixal',
            },
        },
        {
            id: 'sell-Loures',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsLoures.webp',
            header: 'Loures',
            location: 'lisboa/loures',
            translatedLocation: {
                default: 'Loures',
            },
        },
        {
            id: 'sell-Gondomar',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsGondomar.webp',
            header: 'Gondomar',
            location: 'porto/gondomar',
            translatedLocation: {
                default: 'Gondomar',
            },
        },
    ],
    propertiesForRentSection: [
        {
            id: 'rent-Lisboa',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsLisboa.webp',
            header: 'Lisboa',
            location: 'lisboa/lisboa',
            translatedLocation: {
                default: 'Lisboa',
            },
        },
        {
            id: 'rent-Vila-Nova-de-Gaia',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsVilaNovaDeGaia.webp',
            header: 'Vila Nova de Gaia',
            location: 'porto/vila-nova-de-gaia',
            translatedLocation: {
                default: 'Vila Nova de Gaia',
            },
            translationKey: 'frontend.homepage.popular-locations-section.link-label-secondary',
        },
        {
            id: 'rent-Sintra',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsCintra.webp',
            header: 'Sintra',
            location: 'lisboa/sintra',
            translatedLocation: {
                default: 'Sintra',
            },
        },
        {
            id: 'rent-Braga',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsBraga.webp',
            header: 'Braga',
            location: 'braga/braga',
            translatedLocation: {
                default: 'Braga',
            },
        },
        {
            id: 'rent-Porto',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsPorto.webp',
            header: 'Porto',
            location: 'porto/porto',
            translatedLocation: {
                default: 'Porto',
            },
        },
        {
            id: 'rent-Seixal',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsSeixal.webp',
            header: 'Seixal',
            location: 'setubal/seixal',
            translatedLocation: {
                default: 'Seixal',
            },
        },
        {
            id: 'rent-Loures',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsLoures.webp',
            header: 'Loures',
            location: 'lisboa/loures',
            translatedLocation: {
                default: 'Loures',
            },
        },
        {
            id: 'rent-Gondomar',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsGondomar.webp',
            header: 'Gondomar',
            location: 'porto/gondomar',
            translatedLocation: {
                default: 'Gondomar',
            },
        },
    ],
    propertiesFromPrimaryMarketSection: [
        {
            id: 'primary-market-Lisboa',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsLisboa.webp',
            header: 'Lisboa',
            location: 'lisboa/lisboa',
            translatedLocation: {
                default: 'Lisboa',
            },
        },
        {
            id: 'primary-market-Vila-Nova-de-Gaia',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsVilaNovaDeGaia.webp',
            header: 'Vila Nova de Gaia',
            location: 'porto/vila-nova-de-gaia',
            translatedLocation: {
                default: 'Vila Nova de Gaia',
            },
            translationKey: 'frontend.homepage.popular-locations-section.link-label-secondary',
        },
        {
            id: 'primary-market-Sintra',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsCintra.webp',
            header: 'Sintra',
            location: 'lisboa/sintra',
            translatedLocation: {
                default: 'Sintra',
            },
        },
        {
            id: 'primary-market-Braga',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsBraga.webp',
            header: 'Braga',
            location: 'braga/braga',
            translatedLocation: {
                default: 'Braga',
            },
        },
        {
            id: 'primary-market-Porto',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsPorto.webp',
            header: 'Porto',
            location: 'porto/porto',
            translatedLocation: {
                default: 'Porto',
            },
        },
        {
            id: 'primary-market-Seixal',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsSeixal.webp',
            header: 'Seixal',
            location: 'setubal/seixal',
            translatedLocation: {
                default: 'Seixal',
            },
        },
        {
            id: 'primary-market-Loures',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsLoures.webp',
            header: 'Loures',
            location: 'lisboa/loures',
            translatedLocation: {
                default: 'Loures',
            },
        },
        {
            id: 'primary-market-Gondomar',
            imageUrl: '/images/homepage/popularLocationsSection/immovirtualpt/popularLocationsGondomar.webp',
            header: 'Gondomar',
            location: 'porto/gondomar',
            translatedLocation: {
                default: 'Gondomar',
            },
        },
    ],
    seoSchemaMarkupData: {
        potentialAction: {
            target: '/[lang]/resultados/comprar/apartamento/todo-o-pais',
        },
        primaryImageOfPage: {
            url: `${SITE_CONFIG.metadata.preconnectStaticsCDN}/fp_statics${SITE_CONFIG.images.homepageBackground}`,
            width: '1500',
            height: '522',
        },
    },
    usabilitySection: {
        hasBackground: true,
    },
    primaryMarketSection: {
        flatIconPath: '/images/homepage/primaryMarketSection/imovirtual2Flat.svg',
        commercialIconPath: '/images/homepage/primaryMarketSection/imovirtual2CommercialProperty.svg',
        availableSubSection: ['flat', 'house'],
    },
    sellersSection: {
        analyze: {
            linkUrl: 'https://clientes.imovirtual.com/#academia',
            trackingData: {
                touchPointButton: 'for_professionals_academy',
            },
        },
        improve: {
            linkUrl: 'https://www.imovirtual.com/noticias/imoblog/',
            trackingData: {
                touchPointButton: 'for_professionals_analytics',
            },
        },
        explore: {
            linkUrl: 'https://www.imovirtual.com/conta/precos/profissional/',
            trackingData: {
                touchPointButton: 'for_professionals_packages',
            },
        },
    },
    faqSectionItems: [
        {
            id: 'faq-01',
            questionKey: 'frontend.homepage.imo-faq-section.question-1',
            answerKey: 'frontend.homepage.imo-faq-section.answer-1',
        },
        {
            id: 'faq-02',
            questionKey: 'frontend.homepage.imo-faq-section.question-2',
            answerKey: 'frontend.homepage.imo-faq-section.answer-2',
        },
        {
            id: 'faq-03',
            questionKey: 'frontend.homepage.imo-faq-section.question-3',
            answerKey: 'frontend.homepage.imo-faq-section.answer-3',
        },
        {
            id: 'faq-04',
            questionKey: 'frontend.homepage.imo-faq-section.question-4',
            answerKey: 'frontend.homepage.imo-faq-section.answer-4',
        },
    ],
    nexusUsabilitySection: {
        forSeekers: [
            {
                id: '1',
                headline: 'frontend.homepage.nexus-usability-section-for-seekers.tile-1-headline',
                description: 'frontend.homepage.nexus-usability-section-for-seekers.tile-1-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-seekers.tile-1-link-label',
                    href: '/[lang]/resultados/comprar/apartamento/todo-o-pais',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_seekers_sell',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage13.webp',
            },
            {
                id: '2',
                headline: 'frontend.homepage.nexus-usability-section-for-seekers.tile-2-headline',
                description: 'frontend.homepage.nexus-usability-section-for-seekers.tile-2-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-seekers.tile-2-link-label',
                    href: '/[lang]/novo-anuncio/categoria',
                    trackingEventName: 'posting_form_click',
                    touchPointButton: 'why_seekers',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage14.webp',
            },
            {
                id: '3',
                headline: 'frontend.homepage.nexus-usability-section-for-seekers.tile-3-headline',
                description: 'frontend.homepage.nexus-usability-section-for-seekers.tile-3-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-seekers.tile-3-link-label',
                    href: '/[lang]/resultados/arrendar/apartamento/todo-o-pais',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_seekers_rent',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage15.webp',
            },
        ],
        forProfessionals: [
            {
                id: '1',
                headline: 'frontend.homepage.nexus-usability-section-for-professionals.tile-1-headline',
                description: 'frontend.homepage.nexus-usability-section-for-professionals.tile-1-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-professionals.tile-1-link-label',
                    href: 'https://clientes.imovirtual.com/#academia',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_professionals_academy',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage16.webp',
            },
            {
                id: '2',
                headline: 'frontend.homepage.nexus-usability-section-for-professionals.tile-2-headline',
                description: 'frontend.homepage.nexus-usability-section-for-professionals.tile-2-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-professionals.tile-2-link-label',
                    href: 'https://www.imovirtual.com/noticias/imoblog/',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_professionals_analytics',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage17.webp',
            },
            {
                id: '3',
                headline: 'frontend.homepage.nexus-usability-section-for-professionals.tile-3-headline',
                description: 'frontend.homepage.nexus-usability-section-for-professionals.tile-3-description',
                link: {
                    label: 'frontend.homepage.nexus-usability-section-for-professionals.tile-3-link-label',
                    href: 'https://www.imovirtual.com/conta/precos/profissional/',
                    trackingEventName: 'seo_link_click',
                    touchPointButton: 'why_professionals_packages',
                },
                image: '/images/homepage/nexusUsabilitySection/tileImage18.webp',
            },
        ],
    },
    nexusBrandingSection: {
        image: '/images/homepage/brandingSection/imovirtual2Background.webp',
    },
    linkingSection: {
        images: {
            flatsOnSell: '/images/homepage/linkingSection/imo2flatsOnSell.webp',
            housesOnRent: '/images/homepage/linkingSection/imo2housesOnRent.webp',
            housesOnSell: '/images/homepage/linkingSection/imo2housesOnSell.webp',
            terrainsOnSell: '/images/homepage/linkingSection/terrainOnSell.webp',
            commercialPropertiesOnSell: '/images/homepage/linkingSection/commercialPropertiesOnSell.webp',
            flatsOnRent: '/images/homepage/linkingSection/flatsOnRent.webp',
            roomsOnRent: '/images/homepage/linkingSection/roomsOnRent.webp',
            commercialPropertiesOnRent: '/images/homepage/linkingSection/commercialPropertiesOnRent.webp',
        },
    },
    baxterAds: {
        shouldShowbaxterHMiddle1: true,
    },
};
