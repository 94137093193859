import type { SchemaMarkupConfig } from '@config/schemaMarkup/type';
import { SITE_CONFIG } from '@config/siteConfig';

const BASE_URL = `https://${SITE_CONFIG.metadata.siteURL}`;

export const SCHEMA_MARKUP_CONFIG: SchemaMarkupConfig = {
    webPage: {
        '@type': 'WebPage',
        url: BASE_URL,
        isPartOf: {
            '@type': 'WebSite',
            name: 'Imovirtual',
        },
        about: {
            '@type': 'Organization',
            foundingDate: '2011',
            logo: `${SITE_CONFIG.metadata.preconnectStaticsCDN}/fp_statics${SITE_CONFIG.images.logo}`,
            brand: 'OLX Group',
            alternateName: [
                'imovirtual',
                'imovirtual.com',
                'imovirtual olx',
                'imovirtual lisboa',
                'imovirtual porto',
                'imovirtual pt',
                'imovirtual aveiro',
                'www.imovirtual.com',
                'imovirtual com',
                'imovirtual casa',
            ],
            sameAs: [
                'https://www.facebook.com/Imovirtual',
                'https://www.instagram.com/imovirtual/',
                'https://www.youtube.com/user/Imovirtual',
                'https://twitter.com/Imovirtual',
                'https://apps.apple.com/us/app/imovirtual-real-estate-portal/id594152735',
                'https://apps.apple.com/pt/app/imovirtual-nova-app/id6476564616',
                'https://play.google.com/store/apps/details?id=com.fixeads.imovirtual&hl=en&gl=US',
                'https://play.google.com/store/apps/details?id=com.olx.realestate.app.imovirtual',
            ],
            address: {
                '@type': 'PostalAddress',
                addressLocality: 'Lisboa',
                postalCode: '1050-094',
                addressRegion: 'Lisboa',
                streetAddress: 'Ed. Atrium Saldanha, nº1, piso 6',
                addressCountry: {
                    '@type': 'Country',
                    name: 'Portugal',
                },
            },
            contactPoint: {
                '@type': 'ContactPoint',
                telephone: '+351 21 073 8058',
                areaServed: 'Portugal',
                contactType: 'customer service',
                availableLanguage: 'Português',
            },
        },
    },
    searchAction: {
        '@type': 'WebSite',
        url: BASE_URL,
        potentialAction: {
            '@type': 'SearchAction',
            'query-input': 'required name=search_term_string',
        },
    },
    product: {
        '@type': 'Product',
        additionalType: 'RealEstateListing',
        url: BASE_URL,
        adUrlPrefix: '/[lang]/comprar/',
        addressCountry: {
            '@type': 'Country',
            name: 'Portugal',
        },
    },
};
